import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const TeamMemberCard = ({ alt = "", image }) => {
  const data = useStaticQuery(PLACEHOLDER)
  return (
    <Wrap>
      <GatsbyImage
        alt={alt}
        image={image || data.file.childImageSharp.gatsbyImageData}
        objectPosition="top center"
        imgStyle={{ objectPosition: "top center" }}
      />
    </Wrap>
  )
}

export default TeamMemberCard

const Wrap = styled.div`
  background: white;
  //border-radius: 4px;
  display: block;
  overflow: hidden;
  //box-shadow: 0 13px 27px 0 rgba(97, 97, 97, 0.25);
  transition: 0.3s all ease-in-out;
  h3 {
    color: ${({ theme }) => theme.colors.midnight["080"]} !important;
  }
  .gatsby-image-wrapper {
    background-position: top center;
    background-color: #f5f5f5;
    //height: 300px;
    width: 100%;
    aspect-ratio: 1;
  }
  div {
    padding: 15px;
  }
  p {
    color: #9ba5ae;
    font-family: Roboto;
    font-size: 14px;
    line-height: 21px;
  }
`

const PLACEHOLDER = graphql`
  {
    file(relativePath: { eq: "team-image-placeholder.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`
